.Homepage-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    height: 100vh;
    width: 100vw;
    padding: 30px 0px;
    background-color: black;
    text-align: center;
    color: white;
}

.Homepage-logo-img {
    width: 300px;
}