.Form-Container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Form {
    margin: 10px auto;
    padding: 20px 30px;
    background-color: #205295;
    border-radius: 20px;
    color: white;
    display: table;
}

.Form-label {
    display: table-row;
}

.Form-input {
    display: table-row;
    padding: 5px;
    border-radius: 10px;
}

.Form-btn {
    margin: 10px 10px 0px 10px;
    background-color: white;
    padding: 5px 20px;
    border-radius: 10px;
    border: none;
    font-weight: bold;
    color: #205295;
}

.LoginForm-btn:hover,
.LoginForm-btn:active {
    background-color: black;
    color: white;
}

.Active-btn {
    width: 100px;
    padding: 5px;
    align-self: center;
    border-radius: 10px;
    border: none;
    background-color: #D642A4;
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
}
.Active-btn:hover,
.Active-btn:active {
    background-color: #E76161;
} 

.Action-btn {
    width: 100px;
    padding: 5px;
    align-self: center;
    border-radius: 10px;
    border: none;
    background-color: #88D642;
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
}
.Action-btn:hover,
.Action-btn:active {
    background-color: #47A992;
}